import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { concatClassNames as cn } from "@system42/core";

import { featureOverviewFeatures } from "../../helpers";
import { HighlightedText, Faqs, urlBlog } from "../../system42";

import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import { ThreeColumnsIcons } from "../../components/ThreeColumnsIcons";
import { HeaderOverlap } from "../../components/HeaderOverlap";
import { Slider } from "../../components/Slider";
import { TwoColumns } from "../../components/TwoColumns";
import { FeatureOverview } from "../../components/FeatureOverview";

import imageIconAiInsights from "../../images/featureIcons/feature-ai-insights.svg";

import imageInsightsKeyMetrics from "../../images/insights-key-metrics.jpg";
import imageInsightsSummary from "../../images/insights-summary.jpg";
import imageInsightsTesterOverview from "../../images/insights-tester-overview.jpg";

import imageLineIconHandOk from "../../images/lineIcons/100pct-satisfaction-guarantee.svg";
import imageLineIconLock from "../../images/lineIcons/lock.svg";
import imageLineIconBrainStrikedThrough from "../../images/lineIcons/brain-striked-through.svg";

import * as helperStyles from "../helpers.module.css";
import * as styles from "./styles/ai-insights.module.css";

export default function AiInsights() {
  return (
    <Layout
      activeMenuItem={"features/ai-insights"}
      title={"AI Insights"}
      metaDescription={
        "AI Insights instantly reveals key results from your user tests by identifying patterns across all test sessions, improving your analysis and decision-making."
      }
    >
      <article>
        <HeaderOverlap
          featureIcon={imageIconAiInsights}
          featureIconAlt={"Magical looking line drawing of stars"}
          overline={"AI Insights"}
          title={
            <>
              <span className={helperStyles.h1Serif}>More insights, </span>
              less&nbsp;effort.
            </>
          }
          titleWidth={"9em"}
          isDisplayCta
          buttonSubtext={"No credit card required"}
          staticImage={(className) => (
            <StaticImage
              src={"../../images/videoPlayerAiInsights.jpg"}
              className={className}
              alt={
                "Auto generated insights of a test's videos with references to transcripts"
              }
            />
          )}
        />

        <h3 className={cn(helperStyles.container, styles.leadin)}>
          AI Insights reveals key results from your user tests by spotting
          patterns in the analysis of all notes and clips – in an instant.
        </h3>

        <section className={cn(helperStyles.container, helperStyles.spacingLg)}>
          <TwoColumns
            className={helperStyles.spacingLg}
            heading={"AI-enhanced Transcripts"}
            content={
              "Instantly access user quotes, genuine reactions, and critical observations without missing a word. With AI-enhanced transcripts, you can capture everything testers say with perfect accuracy, even in challenging audio conditions. Focus on what matters most – understanding your users – while never missing a detail."
            }
            image={(className) => (
              <StaticImage
                className={className}
                src={"../../images/ai-enhanced-transcripts.jpg"}
                alt={"Transcript timeline generated from a tester's video"}
              />
            )}
          />

          <TwoColumns
            className={helperStyles.spacingLg}
            inverted
            heading={"Smart and Time-Stamped Annotations"}
            content={
              "Analyzing user testing data can be challenging and time-consuming. Not anymore. AI Insights takes time-stamped notes of significant key moments, such as potential usability issues, assigns labels and sentiments, and even includes direct quotes from testers."
            }
            image={(className) => (
              <StaticImage
                className={className}
                src={"../../images/smart-time-stamped-annotations.jpg"}
                alt={"A sidebar of annotations next to a tester's video"}
              />
            )}
          />

          <TwoColumns
            className={helperStyles.spacingLg}
            heading={"Instantly Uncover Key Insights"}
            content={
              "AI Insights processes weeks’ worth of data, instantly highlighting key themes and patterns like usability issues, positive findings, or suggestions for improvement.  Add your own annotations and watch as AI Insights regenerates all key insights, seamlessly incorporating your unique observations."
            }
            image={(className) => (
              <StaticImage
                className={className}
                src={"../../images/instantly-uncover-insights.jpg"}
                alt={"A list of usability issues"}
              />
            )}
          />

          <TwoColumns
            className={helperStyles.spacingLg}
            inverted
            heading={"No More Video Marathons"}
            content={
              "Jump straight to the good stuff without rewatching the entire video. Key insights are time-linked to the exact source, so whether the issue occurs with one tester or eight, you can click on the finding and be guided directly to the precise spot in the video."
            }
            image={(className) => (
              <StaticImage
                className={className}
                src={"../../images/no-more-video-marathons.jpg"}
                alt={"A list of short video clips and a floating mini player"}
              />
            )}
          />

          <TwoColumns
            className={helperStyles.spacingLg}
            heading={"All-In-One Overview"}
            content={
              "Keep your entire team in the loop with an organized summary of what’s been tested. Access tester demographics and key metrics like time on task. Perfect for larger teams, ensuring everyone stays aligned and up-to-date."
            }
            image={(className) => (
              <StaticImage
                className={className}
                src={"../../images/all-in-one-overview.jpg"}
                alt={"A test dashboard with high-level information"}
              />
            )}
          />
        </section>

        <Slider
          isDisplayControls={false}
          className={helperStyles.spacingLg}
          title="Instant access to a presentation-ready overview of your user test."
          titleWidth={"18em"}
          slides={[
            {
              imageSrc: imageInsightsSummary,
              imageAlt:
                "Tab in insights dashboard showing a summary of a user test",
            },
            {
              imageSrc: imageInsightsTesterOverview,
              imageAlt:
                "Tab in insights dashboard showing an overview of testers",
            },
            {
              imageSrc: imageInsightsKeyMetrics,
              imageAlt:
                "Tab in insights dashboard showing key metrics of a user test",
            },
          ]}
        />

        <section className={cn(helperStyles.container, helperStyles.spacingLg)}>
          <ThreeColumnsIcons
            className={helperStyles.spacingLg}
            title={
              <>
                <span className={helperStyles.h2Serif}>Your Data, </span>
                <strong className={helperStyles.underline}>
                  Your Control.
                </strong>
              </>
            }
            //
            col1Image={imageLineIconHandOk}
            col1Alt={"Hand OK gesture"}
            col1Heading={"Secure and Reliable"}
            col1Text={
              "We take data safety and security seriously and protect your data by using industry-leading controls and systems that prevent unauthorized access."
            }
            //
            col2Image={imageLineIconLock}
            col2Alt={"Lock"}
            col2Heading={"GDPR Compliant"}
            col2Text={
              "Our AI features are fully GDPR compliant, ensuring that all data processing adheres to the highest standards of data protection. "
            }
            //
            col3Image={imageLineIconBrainStrikedThrough}
            col3Alt={"Brain striked through"}
            col3Heading={"No AI Training"}
            col3Text={
              "Enjoy powerful insights without compromising on privacy, knowing your data is not utilized to train any AI models outside of your testing."
            }
          />
        </section>

        <Faqs
          className={cn(helperStyles.container, helperStyles.spacingLg)}
          questions={[
            {
              question: "Are AI Insights included with my subscription?",
              answer:
                "Good news, all subscriptions include AI Insights. You'll have access to AI Insights as long as your subscription is active. Please note, AI Insights are not available with our Pay-As-You-Go plan.",
            },
            {
              question: "How can I share AI Insights with my team?",
              answer: (
                <>
                  You can either share all AI Insights in an{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${urlBlog}/new-feature-get-better-insights-with-reports`}
                  >
                    automated test report
                  </a>{" "}
                  for your team, or turn specific AI Insights into clips and
                  share them with your team or anyone via a link.
                </>
              ),
            },
            {
              question: "Can I make my own notes?",
              answer:
                "Yes. And it's the easiest way of note-taking you can imagine. Seriously – give it a try. You can also edit AI Insights and turn any note into a clip if you want to highlight something.",
            },
          ]}
        />

        {/* XXX: Specificity issue */}
        <div className={helperStyles.container}>
          <GetStartedBox
            className={helperStyles.spacingLg}
            titleWidth={"12em"}
            title={
              <>
                You want <HighlightedText cursor>AI Insights</HighlightedText>{" "}
                with every user test?
              </>
            }
          />
        </div>

        <div
          className={cn(
            helperStyles.container,
            helperStyles.container_featureOverview
          )}
        >
          <FeatureOverview
            className={helperStyles.spacingLg}
            title={
              "Userbrain features for easy, unmoderated remote user testing"
            }
            titleWidth={"16em"}
            features={featureOverviewFeatures}
          />
        </div>
      </article>
    </Layout>
  );
}
